
import { Link } from "react-router-dom";

export default function Footer() {
    return (

        <div className="conteudo-footer" >

            <Link to="/sobre">Sobre o Marnie Books</Link>
            <Link to="/contato">Informações de Contato</Link>
            <a href="https://www.gutenberg.org/policy/privacy_policy.html" target="_blank">Política de Privacidade</a>
            
        </div>
    )
}