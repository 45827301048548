import Footer from '../Footer';
import Header from '../Header'
import { Link, useNavigate } from "react-router-dom";
import { useState } from 'react';
import ResetSenhaEmail from '../ResetSenhaEmail';

export default function Login() {
    const [termoBusca, setTermoBusca] = useState("")
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })

    const handleFormEdit = (event, name) => {
        setFormData({
            ...formData,
            [name]: event.target.value
        })
    }

    const handleForm = async (event) => {
        try {

            event.preventDefault()
            const response = await fetch('https://api.marniebooks.com.br/auth/authenticate', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData)
            })

            const json = await response.json()

            if (response.status !== 200) {
                throw new Error(json.error)
            }

            sessionStorage.setItem('token', json.token)
            sessionStorage.setItem('nome', json.user.nome)

            return navigate("/favoritos");
        } catch (error) {
            alert(error.message)
        }
    }
     //função que dá click a tecla enter para enviar dados
    function handleKeyPress(inputValue, event) {
        if (event.key === 'Enter') {
           handleForm(event)
        }
    }
    return (
        <div>
            <Header />
            <div className='container-fluid'>
                <section>

                    <div className='texto-acima-input'>
                        <h3 id='title-login'>Faça login para continuar</h3>
                    </div>
                    <div className='form'>
                        <label style={{ marginBottom: '5px' }}>E-mail:</label>
                        <input type="email" className='input-login' required value={formData.email} onChange={(e) => { handleFormEdit(e, 'email') }} onKeyDown={event => handleKeyPress(event.target.value, event)}></input>

                        <label style={{ marginTop: '15px', marginBottom: '5px' }}>Senha:</label>
                        <input type="password" className='input-login' required value={formData.password} onChange={(e) => { handleFormEdit(e, 'password') }} onKeyDown={event => handleKeyPress(event.target.value, event)}></input>
                    </div>

                    <div style={{ marginTop: '20px' }} className='container-bottom'>
                        <button className='button-login'  onClick={handleForm} style={{cursor: 'pointer'}}>Entrar</button>
                    </div>
                    <div className='text-bottom-input'>
                        <Link style={{ textDecoration: 'underline' }} className='text-bottom-input' to="/criar">
                            <p>É novo por aqui? Crie sua conta!</p></Link>
                        <Link style={{ textDecoration: 'underline', cursor: "pointer", color: "#000", fontSize: "15px" }} to="/reset-senha-informe-email" >Esqueceu a senha?</Link>

                    </div>

                </section>
            </div>
            <footer><Footer /></footer>
        </div>


    )
} 