import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import ListarLivros from "../ListaLivros";
import {BuscarLivros} from "../../servicos/livros";
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'



export default function Busca(){
//fica sempre olhando para url atual, após digitado um termo na url, é apagado a rota em () e acionado
// a função de requisição de livro, passando como parâmetro o texto digitado
    const [livros, setLivros] = useState([])
    const location = useLocation()
    useEffect(() => {
        console.log(location)
        const termoBusca = location.pathname.replace("/busca/", "")
        fetchLivros(termoBusca)
    },[location])
//faz uma requisição na API para buscar o livro digitado pelo usuário
    async function fetchLivros(textoDigitado) {
        const livrosDaAPI = await BuscarLivros(textoDigitado)
        setLivros(livrosDaAPI)

    }

    return(
        
        <div>
            <Header/>
            <ListarLivros livros={livros}/>
            <Footer/>
        </div>
    )
}