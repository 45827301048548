import { Link } from "react-router-dom"
import Header from "../Header"


export default function VerifiqueSeuEmail() {
    return (
        <div>
            <Header />

            <div className='container-fluid'>
                <section>
                    <div className='texto-acima-input'>
                        <h3 id='title-login'>Em breve, você receberá um e-mail com as instruções para redefinir <br></br> 
                        sua senha. Por favor, não demore pois sua redefinição de senha pode expirar.</h3>

                    </div>
                    <div style={{ marginTop: '20px' }} className='container-bottom'>
                      <Link className='button-login' to="/login" style={{textDecoration:"none"}}>Ir para o login</Link>

                    </div>
                </section>
            </div>

        </div>
    )
}