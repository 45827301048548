import Header from "../Header";
import Footer from "../Footer";
import getLivros from "../../servicos/livros";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { FaHeartCirclePlus, FaHeartCircleMinus } from "react-icons/fa6";





export default function Livro() {
    const { id } = useParams();
    const [livro, setLivros] = useState({})
    const [favoritos, setFavoritos] = useState([])

useEffect(() => {
    fetchLivros(id)
},[])
    useEffect(() => {
       
        const favoritos = JSON.parse(localStorage.getItem("favoritos"))
        setFavoritos(favoritos)
    }, [favoritos])

    async function fetchLivros(id) {
        const livrosDaAPI = await getLivros(id)
        setLivros(livrosDaAPI)
    }
    const lerOnline = (link) => {
        window.open(link, "_blank", "noreferrer")
    }

    const kindleEpub = (link) => {
        window.open(link, "_blank", "noreferrer")
    }

    const addFavorito = (livro) => {
        alert("Adicionado aos favoritos ♡!"
        )
        if (!favoritos) {
            localStorage.setItem("favoritos", JSON.stringify([livro]))
            return
        }
        localStorage.setItem("favoritos", JSON.stringify([...favoritos, livro]))

    }

    const removeFavorito = (livro) => {
        if (favoritos) {

            const livroFilter = favoritos.filter((item) => {

                return item._id !== livro._id
            })
            localStorage.setItem("favoritos", JSON.stringify(livroFilter))
            setFavoritos(livroFilter)
            alert("Removido dos favoritos ☺︎!")
            return
        }
    }

    const showButtonFavoritos = () => {
        const token = sessionStorage.getItem('token')

        if (token) {

            return showCurrentButton()

        } else {
            return ""
        }
    }

    const showCurrentButton = () => {

        const favoritos = JSON.parse(localStorage.getItem("favoritos"))

        if (favoritos) {

            const livroFilter = favoritos.filter((item) => {

                return item._id === livro._id
            })
            console.log(livroFilter.length > 0, livroFilter)
            if (livroFilter.length > 0) {
                return (
                    <span onClick={() => { removeFavorito(livro) }}>
                        <FaHeartCircleMinus style={{ fontSize: '33px', padding: '8px', cursor: 'pointer' }} />
                    </span>
                )
            }else{
                return (
                    <span onClick={() => { addFavorito(livro) }} className="addFavoritos">
                        <FaHeartCirclePlus style={{ fontSize: '33px', padding: '8px', cursor: 'pointer' }} />
                      </span>)
            }
            
        } else {
            return (
                    <span onClick={() => { addFavorito(livro) }} className="addFavoritos">
                        <FaHeartCirclePlus style={{ fontSize: '33px', padding: '8px', cursor: 'pointer' }} />
                      </span>)
        }
    }
    return (

        <div>
            <div><Header /></div>


            <h2 className="nome-livro">{livro.nome}</h2>

            <div className="container-principal">
                <div className="container-livro">

                    <img className="livro-img" src={livro.img} />
                    <div className="conteudo-button-livro">
                        <button onClick={() => lerOnline(livro.lerOnline)}>Ler Online</button>
                        <button onClick={() => kindleEpub(livro.kindleEpub)} >Baixar Epub</button>


                    </div>
                    <div>
                   {showButtonFavoritos()}
                    </div>

                </div>
                <div className="infos-livro">
                    <p><strong>Autor: </strong>{livro.autor}</p>
                    <p><strong>Título: </strong>{livro.titulo}</p>
                    <p><strong>Assunto: </strong>{livro.assunto}</p>

                    <p><strong>Data de publicação: </strong>{livro.dataLancamento}</p>
                    <p><strong>Status sobre direitos autorais: </strong>{livro.statusDireitoAutorais}</p>
                </div>



            </div>

            <div><Footer /></div>
        </div>
    )
}