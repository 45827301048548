import React, { useEffect, useState } from "react"
import Header from "../Header"
import { useLocation } from "react-router-dom"


export default function ResetSenha() {
    const location = useLocation()
    useEffect(() => {
console.log(location)
        const token = location.search.replace("?token=", "")
    },[])

    return (
        <div>
            <Header />

            <div className='container-fluid'>
                <section>
                    <div className='texto-acima-input'>
                        <h3 id='title-login'>Altere sua senha</h3>

                    </div>

                    <div className='form'>
                        <label style={{ marginBottom: '5px' }}>Nova senha:</label>
                        <input type="text" className='input-login'></input>
                    </div>

                    <div className='form'>
                        <label style={{ marginBottom: '5px' }}>Repita a nova senha:</label>
                        <input type="text" className='input-login'></input>
                    </div>

                    <div style={{ marginTop: '20px' }} className='container-bottom'>
                        <button className='button-login'>Cadastrar</button>

                    </div>
                </section>
            </div>

        </div>
    )
}