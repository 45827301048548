import Header from "../Header"
import Footer from "../Footer"

export default function Contato() {
    return (
        <section>
            <div>
                <Header />
            </div>
            <div className="title-sobreMarnie">
                <h2>Informações de contato</h2>
            </div>
            <div className="conteudo-sobreMarnie">
                <p>
                    Você poderá contactar a desenvolvedora deste site atráves da rede social <br /><br />

                    Gil Machado: <a style={{ color: '#000' }} href="https://www.linkedin.com/in/gilderlane-machado/" target="_blank" rel="noreferrer">Linkedin</a> <br />
                   
                </p>
            </div>
            <footer>
                <Footer />
            </footer>
        </section>

    )
}