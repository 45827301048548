import './App.css';
import ResetSenha from './componentes/ResetSenha'
import Login from './componentes/Login'
import CriarConta from './componentes/CriarConta'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Vitrine from './componentes/Vitrine';
import SobreMarnie from './componentes/SobreMarnie';
import Contato from './componentes/Contato';
import Livro from './componentes/Livro'
import Favoritos from './componentes/Favoritos';
import Busca from './componentes/Busca';
import ResetSenhaEmail from './componentes/ResetSenhaEmail';
import VerifiqueSeuEmail from './componentes/VerifiqueSeuEmail';


<style>
  @import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@1,300&family=Chakra+Petch:wght@300&family=Open+Sans&display=swap');
</style>

export default function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Vitrine/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/criar' element={<CriarConta/>}/>
        <Route path='/contato' element={<Contato/>}></Route>
        <Route path='/sobre' element={<SobreMarnie/>}/>
        <Route path='/livro/:id' element={<Livro/>}/>
        <Route path='/favoritos' element={<Favoritos/>}/>
        <Route path='/busca/:termo' element={<Busca/>}/>
        <Route path='/reset-senha' element={<ResetSenha/>}/>
        <Route path='/reset-senha-informe-email' element={<ResetSenhaEmail/>}/>
        <Route path='/verifique-seu-email' element={<VerifiqueSeuEmail/>}/>

      </Routes>
    </BrowserRouter>
  
        
    
   
  );
}


