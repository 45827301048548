import Header from '../Header'
import Footer from '../Footer';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import * as yup from 'yup';


export default function Login() {
    //infos para salvar na rota de register
   const navigate = useNavigate()
    const [formData, setFormData] = useState({
        nome: '',
        email: '',
        password: '',
    })

//validação dos campos
    const registerSchema = yup.object({
        email: yup
            .string()
            .email('O e-mail não é válido')
            .required('O e-mail é obrigatório'),
        nome: yup
            .string()
            .required('O nome é obrigatório'),
        password: yup
            .string()
            .required('A senha é obrigatória')
    });



    const handleFormEdit = (event, name) => {
        setFormData({
            ...formData,
            [name]: event.target.value
        })
    }

    const handleForm = async (event) => {
        try {

            event.preventDefault()
            try {
                await registerSchema.validate(formData)

            } catch (error) {
                alert(error.message)
                return

            }//salvando as infos do user na request
            const response = await fetch('https://api.marniebooks.com.br/auth/register', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData)
            })

            const json = await response.json()
            //salvando as infos do user atual no sessionStorage para mostrar o nome dele na tela
            sessionStorage.setItem('token', json.token)
            sessionStorage.setItem('nome', json.user.nome) 

            return navigate("/");
        } catch (error) {
        }
    }

     //função que dá click a tecla enter para enviar dados
    function handleKeyPress(inputValue, event) {
        if (event.key === 'Enter') {
           handleForm(event)
        }
    }

    return (
        <div>
            <Header />
            <div className='container-fluid'>
                <section>
                    <div className='texto-acima-input'>
                        <h3 id='title-login'>Criar uma conta</h3>

                    </div>

                    <div className='form'>
                        <label style={{ marginBottom: '5px' }}>Nome:</label>
                        <input onKeyDown={event => handleKeyPress(event.target.value, event)} type="text" className='input-login' value={formData.nome} onChange={(e) => { handleFormEdit(e, 'nome') }}></input>

                        <label style={{ marginBottom: '5px' }}>E-mail:</label>
                        <input onKeyDown={event => handleKeyPress(event.target.value, event)} type="email" className='input-login' value={formData.email} onChange={(e) => { handleFormEdit(e, 'email') }}></input>


                        <label style={{ marginTop: '15px', marginBottom: '5px' }}>Senha:</label>
                        <input  onKeyDown={event => handleKeyPress(event.target.value, event)}type="password" className='input-login' value={formData.password} onChange={(e) => { handleFormEdit(e, 'password') }}></input>
                    </div>

                    <div style={{ marginTop: '20px' }} className='container-bottom'>
                        <button className='button-login' onClick={handleForm} style={{cursor: 'pointer'}}>Cadastrar</button>

                    </div>
                </section>
            </div>
            <footer><Footer /></footer>
        </div>


    )
} 