import axios from "axios";


export default async function getLivros(id, query){
    const livrosAPI = axios.create({baseURL:"https://api.marniebooks.com.br/livros"})

    let response
    if(id){
         response = await livrosAPI.get(`/${id}`)
    }else if(query){
     response = await livrosAPI.get(`?${query}`)

    }
    else{
         response = await livrosAPI.get('/')
    }
   return response.data
}

 //busca de livro na lupa
export async function BuscarLivros(termo){
     
     const BuscarLivrosAPI = axios.create({baseURL:`https://api.marniebooks.com.br/livros/`})

     let responseBusca = await BuscarLivrosAPI.get(`busca?search=${termo}`)

     return responseBusca.data

}