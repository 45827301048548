import { Link } from 'react-router-dom'
import { useRef } from 'react'
import icon from '../../imagens/icon-passe.png'
export default function Carrousel(props) {

    //função do carrousel de livros
    const carousel = useRef(null)
    //passar os livros pra frente
    const handleRightClick = (e) => {
        e.preventDefault()
        carousel.current.scrollLeft += carousel.current.offsetWidth
        console.log(carousel)

    }
    //voltar os livros
    const handleLeftClick = (e) => {
        e.preventDefault()
        carousel.current.scrollLeft -= carousel.current.offsetWidth
    }
    return (
        <div className='carousel' ref={carousel}>
            {props.livros.map(livro => {
                const { _id, nome, img } = livro;
                return (

                    <div className='item' key={_id}>
                        <Link to={`/livro/${_id}`}>
                            <div className='image'>
                                <img src={img} />
                            </div>
                            <div className='info'>
                                <span>{nome}</span>
                            </div>
                        </Link>
                    </div>

                )
            }

            )}
            <div className='buttons'>
                <button onClick={handleLeftClick} className='button-scrol scroll-left'>
                    <img alt='Scroll left ' src={icon}></img>
                </button>
                <button onClick={handleRightClick} className='button-scrol scroll-right'>
                    <img alt='Scroll right' src={icon}></img>
                </button>
            </div>
        </div>


    )

}