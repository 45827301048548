import logo from '../../imagens/logo.png'
import { Link, useNavigate } from "react-router-dom";
import { BsPersonCircle } from 'react-icons/bs';
import React from 'react';
import { useEffect, useState } from 'react';
import { IoIosHeartEmpty } from "react-icons/io";
import { AiOutlineLogout } from "react-icons/ai";





function Header() {

    const [nome, setNome] = useState("")
    const navigate = useNavigate();
    const [termoBusca, setTermoBusca] = useState("")
    const [token, setToken] = useState("")


    useEffect(() => {
        const token = sessionStorage.getItem('token')


        if (token) {
            const nome = sessionStorage.getItem('nome')
            setNome(nome)
            setToken(token)
        }
    }, [token]);

    function handleKeyPress(inputValue, event) {
        if (event.key === 'Enter') {
            if (termoBusca)
                navigate(`/busca/${termoBusca}`);
        }
    }
//função que mostra o ícone coraçãozinho da lista de favoritos
    const showListaFavoritos = () => {

        if (token) {

            return (<Link to="/favoritos"><IoIosHeartEmpty style={{ fontSize: '30px', color: '#000' }} /></Link>)

        } else {
            return ""
        }
    }
//função que realiza a logout da página, ou seja, remove os dados do session storage
    const logout = () =>{
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("nome")

        setToken("")
        setNome("")
    } 
//função que mostra o ícone de logout caso o usuário esteja logado na página
    const showButtonLogout = () => {

        if (token) {
            return (<div onClick={logout}><AiOutlineLogout  style={{ fontSize: '28px', padding: '3px' }} /></div>)
        } else {
            return
           

        }

    }

    return (
        <header className="header">
            <Link style={{ textDecoration: 'none', color: '#000' }} to="/"><div className='container-logo'>
                <img src={logo} alt='logo' className='logo-image'></img>
                <p style={{ fontSize: '26px', margin: '0' }}><strong>Marnie </strong>Books</p>
            </div></Link>
            <div className='header-pesquisa'>
                <div class="search-box">
                    <input onKeyDown={event => handleKeyPress(event.target.value, event)} class="search-input" type="text" onChange={(ev) => { setTermoBusca(ev.target.value) }}
                        placeholder="O que você está procurando?"
                    />
                    <a onClick={() => { navigate(`/busca/${termoBusca}`); }} class="search-btn"><i class="fas fa-search"></i></a>
                </div>

                {showListaFavoritos()}
                <Link to="/login" ><BsPersonCircle style={{ fontSize: '27px', color: '#000', padding: '8px' }} /></Link>

                {nome}

        {showButtonLogout()}



            </div>
        </header>
    )
} export default Header;