import { Link, useNavigate } from "react-router-dom"
import Header from "../Header"
import { useState } from "react"


export default function ResetSenhaEmail() {
    const navigate = useNavigate()
    const [formData, setFormData] = useState("")

    const handleFormEdit = (event, name) => {
        setFormData(event.target.value)
    }

    const handleForm = async (event) => {
        try {

            event.preventDefault()
            const response = await fetch('https://api.marniebooks.com.br/auth/forgot_password', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({email: formData})
            })

            const json = await response.json()

            if (response.status !== 200) {
                throw new Error(json.error)
            }
            return navigate("/verifique-seu-email")
        } catch (error) {
            alert(error.message)
        }


    }
    //função que dá click a tecla enter para enviar dados
    function handleKeyPress(inputValue, event) {
        if (event.key === 'Enter') {
           handleForm(event)
        }
    }
    return (
        <div>
            <Header />

            <div className='container-fluid'>
                <section>
                    <div className='texto-acima-input'>
                        <h3 id='title-login'>Preencha abaixo seu endereço de email para receber <br></br> as instruções necessárias e criar uma nova senha</h3>

                    </div>

                    <div className='form'>
                        
                        <input onKeyDown={event => handleKeyPress(event.target.value, event)} required value={formData.email} onChange={(e) => {handleFormEdit(e, 'email') }} type="text" 
                        className='input-login' placeholder="Seu email"></input>
                    </div>

                    

                    <div style={{ marginTop: '20px' }} className='container-bottom'>

                      <button className='button-login' onClick={handleForm} style={{textDecoration:"none", cursor: 'pointer'}}>Recuperar minha senha</button>  

                    </div>
                </section>
            </div>

        </div>
    )
}