import React from "react"
import { Link } from "react-router-dom"


export default function ListarLivros(props) {

    return (
        <div className="container-listaLivro">
            {props.livros.map(livro => {
                const { _id, titulo, img } = livro;
                return (
                    <div className="container-principal-listaLivro"  key={_id}>
                        <Link className="listaLivro" to={`/livro/${_id}`}>
                            <div className="image-livro">
                                <img src={img} alt="Imagem livro" />
                            </div>
                            <div className="infos-livro">
                                <span style={{ color: '#000'}}>{titulo}</span>
                            </div>
                        </Link>
                    </div>
                )
            }

            )}
        </div>

    )
}