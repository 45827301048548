import Header from '../Header'
import Footer from '../Footer'
import getLivros from '../../servicos/livros'
import Carrousel from '../Carrousel/index.js'
import { useEffect, useState } from 'react'


export default function Vitrine() {
    const [livros3, setLivros3] = useState([])
    const [livros2, setLivros2] = useState([])
    const [livros, setLivros] = useState([])
    useEffect(() => {
        fetchLivros()
        fetchLivros2()
        fetchLivros3()

    }, [])

 

    async function fetchLivros(textoDigitado) {
        const livrosDaAPI = await getLivros(textoDigitado)
        setLivros(livrosDaAPI)
        
    }

    async function fetchLivros2(textoDigitado) {
        const livrosDaAPI = await getLivros(null, "page=2")
        setLivros2(livrosDaAPI)
        
    }

    async function fetchLivros3(textoDigitado) {
        const livrosDaAPI = await getLivros(null, "page=3")
        setLivros3(livrosDaAPI)
        
    }

    return (
        <div>
            <div> <Header /> </div>
            <div>
                <h2 className='title-vitrine'>Os livros clássicos estão a sua espera!</h2>
            </div>

            <div style={{marginBottom:"7%"}}>
                <Carrousel livros={livros}/>
                <Carrousel livros={livros2}/>
                <Carrousel livros={livros3}/>
            </div>

            <footer><Footer /></footer>
        </div>

    )
}