import Header from "../Header";
import Footer from "../Footer";

export default function SobreMarnie() {
    return (
        <section>
            <div>
                <Header />
            </div>
            <div className="title-sobreMarnie">
                <h2>Sobre o Projeto Marnie Books</h2>
             </div>
            <div className="conteudo-sobreMarnie">
                <p>
                    O Marnie Books é uma biblioteca online de e-books gratuitos,
                    com a missão e objetivo principal de incentivar a leitura,
                    tendo como principal acervo o <a style={{color:'#000'}} href="https://www.gutenberg.org/" target="_blank">Projeto Gutemberg</a>,
                    para livros na língua Portuguesa.
                    Neste projeto você não encontrará livros que tenha proteção de
                    direitos autorais. Isso significa que você não encontrará livros
                    recentes ou modernos. Você encontrará livros clássicos do ínício do
                    século 20 e séculos anteriores, são somente livros de domínio públicos.
                </p>
            </div>
            <footer>
                <Footer/>
            </footer>
        </section>
    )
}