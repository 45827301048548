import Header from "../Header";
import Footer from "../Footer";
import { useEffect, useState } from "react";
import ListarLivros from "../ListaLivros"



export default function Favoritos(){
    const [nome, setNome] = useState("")

    const [livroFavorito, setlivroFavorito] = useState([])
    useEffect(() => {
        const favoritos = JSON.parse(localStorage.getItem("favoritos"))
        if(favoritos){
        setlivroFavorito(favoritos)
}
        const nome = sessionStorage.getItem('nome')
        setNome(nome)

    }, [])

    return(

       

        <div>
            <Header/>
            <div>
                <h2  className='title-vitrine'> Olá {nome}, aqui estão seus livros favoritos</h2>
            </div>

            <div>
             <ListarLivros livros={livroFavorito}/>
                
            </div>
           
            <Footer/>
        </div>
    )
}